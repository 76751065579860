import React from 'react';
import { currencyMask, makeDropDownOptions, toCurrency } from '../../utils';
import { Box, Grid, Button, Separator, Typography } from '../library';
import CardActions from '../library/cards/CardActions';
import {
  CardForm,
  DropDownField,
  ImageField,
  MultipleItemsField,
  TextField,
  Label,
  RadioField,
} from '../library/forms';
import RecipeFormContainer from '../recipes/RecipeFormContainer';
import SupplyFormContainer from '../supplies/SupplyFormContainer';
import theme, { spacing } from '../library/theme';
import { css } from 'glamor';
import { FormHelperText } from '@mui/material';

const imageWrapperSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: spacing.s,
};
const imageSx = {
  width: 100,
};
const newProductSx = {
  display: 'flex',
  gap: 3,
  marginTop: 0,
};
const labelClass = css({
  fontSize: 12,
  fontWeight: 'bold',
  margin: `${spacing.m} auto`,
  color: theme.palette.primary.main,
  cursor: 'pointer',
});
const errorsSx = css({
  whiteSpace: 'pre-wrap',
});

export default function ProductForm(props) {
  const {
    recipes,
    products,
    product,
    strategicRoles,
    newProduct,
    ingredients,
    decorations,
    packagings,
    cost,
    costErrors,
    currency,
  } = props;

  const onModal = !!props?.closeCreateModal;

  return (
    <CardForm {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField name="name" label="Nome" required fluid />
        </Grid>
        <Grid item xs={12}>
          <DropDownField
            name="productCategoryId"
            label="Categoria"
            data={makeDropDownOptions(
              props.productCategories,
              'Selecione uma opção',
            )}
            required
            fluid
          />
        </Grid>
        <Grid item xs={12}>
          <DropDownField
            name="strategicRoleId"
            label="Função Estratégica"
            data={makeDropDownOptions(
              strategicRoles,
              'Selecione uma opção',
              true,
            )}
            fluid
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Separator />
          <Box sx={imageWrapperSx}>
            <Box sx={imageSx}>
              <ImageField name="imageUrl" />
            </Box>
            <Box>
              <label htmlFor={'imageUrl'} className={labelClass}>
                Adicionar imagem
              </label>
            </Box>
          </Box>
          <Separator />
        </Grid>
        <Grid item xs={12}>
          <MultipleItemsField
            name="productsBundlesAttributes"
            data={products}
            label="Produtos"
            placeholder="Selecione um produto existente"
            searchInputPlaceholder="Selecione um produto existente"
            searchInputName="bundledProductId"
            editEnabled
            costEnabled={!costErrors}
            currency={currency}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleItemsField
            name="productsRecipesAttributes"
            data={recipes}
            label="Receitas"
            placeholder="Selecione uma receita existente"
            searchInputPlaceholder="Selecione uma receita existente"
            searchInputName="recipeId"
            editEnabled
            costEnabled={!costErrors}
            createForm={!onModal && RecipeFormContainer}
            createLabel={!onModal && 'Criar nova receita'}
            currency={currency}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleItemsField
            name="ingredientsAttributes"
            data={ingredients}
            label="Ingredientes"
            placeholder="Selecione um ingrediente existente"
            searchInputPlaceholder="Selecione um ingrediente existente"
            searchInputName="supplyId"
            editEnabled
            costEnabled={!costErrors}
            createForm={!onModal && SupplyFormContainer}
            createLabel={!onModal && 'Criar novo ingrediente'}
            currency={currency}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleItemsField
            name="decorationsAttributes"
            data={decorations}
            label="Decorações"
            placeholder="Selecione uma decoração existente"
            searchInputPlaceholder="Selecione uma decoração existente"
            searchInputName="supplyId"
            editEnabled
            costEnabled={!costErrors}
            createForm={!onModal && SupplyFormContainer}
            createLabel={!onModal && 'Criar nova decoração'}
            currency={currency}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleItemsField
            name="packagingsAttributes"
            data={packagings}
            label="Embalagens"
            placeholder="Selecione uma embalagem existente"
            searchInputPlaceholder="Selecione uma embalagem existente"
            searchInputName="supplyId"
            editEnabled
            costEnabled={!costErrors}
            createForm={!onModal && SupplyFormContainer}
            createLabel={!onModal && 'Criar nova embalagem'}
            currency={currency}
          />
        </Grid>
      </Grid>
      <Separator />
      {!costErrors && (
        <div>
          Custo estimado do produto:{' '}
          <strong style={{ color: 'black' }}>
            {toCurrency(cost, currency?.code)}
          </strong>
          *
          <FormHelperText>
            *Valor arredondado para a quantia monetária mais próxima.
          </FormHelperText>
        </div>
      )}
      {!!costErrors && (
        <div className={errorsSx}>
          <strong>Custo estimado do produto: </strong>
          {costErrors}
        </div>
      )}
      <Separator />
      <Grid item xs={12}>
        <TextField
          name="amount"
          label="Preço"
          required
          fluid
          {...currencyMask(currency?.symbol)}
        />
        <TextField
          name="marketPrice"
          label="Preço médio de mercado"
          fluid
          {...currencyMask(currency?.symbol)}
        />
        <TextField
          name="maxPrice"
          label="Preço máximo que seus clientes aceitariam pagar"
          fluid
          {...currencyMask(currency?.symbol)}
        />
        <TextField
          name="inStock"
          label="Quantidade em estoque"
          type="number"
          minValue="0"
          required
          fluid
        />
        <TextField
          type="number"
          name="productionTime"
          label="Tempo de produção em minutos (opcional)"
          hint="Quanto tempo você leva para produzir o produto (desconsiderar tempo de receitas)?"
          minValue="1"
          fluid
        />
      </Grid>
      <Separator />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" weight="bold">
            Quantos produtos você vende em um mês?
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <Label>É um produto novo?</Label>
          <Box sx={newProductSx}>
            <RadioField name="newProduct" value={true} label="Sim" />
            <RadioField name="newProduct" value={false} label="Não" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="quantity"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            step="1"
            label={
              newProduct == 'true'
                ? 'Projeção de Quantidade de Vendas Mensal'
                : 'Quantidade média vendida num mês'
            }
            fluid
          />
        </Grid>
      </Grid>

      <CardActions>
        <Button
          type="button"
          onClick={props.handleSubmit}
          color="success"
          size="large"
          disabled={props.pristine || props.submitting}
          fluid
        >
          {product ? 'Atualizar' : 'Adicionar Produto'}
        </Button>
      </CardActions>
    </CardForm>
  );
}
