import { normalize, schema } from 'normalizr';
import { apiGet, apiPut } from './helpers/api';
import middleware from './helpers/railsMiddleware';
import { put } from 'superagent';
import { SubmissionError } from 'redux-form';
import { notifySubmitFailed, notifySubmitSucceeded } from './notifierProcesses';
import toQueryString from '../utils/toQueryString';

const backgroundTask = new schema.Entity('backgroundTasks');

const isFile = (obj) => obj && !!obj.lastModified;

export const fetchBackgroundTasks = (dispatch, props) => {
  const currentPage = props?.currentPage || 1;
  const pageParams = props?.paged == true ? `page=${currentPage}` : '';
  const params = {
    ...props?.filters,
  };
  const filterParams = toQueryString(params);

  dispatch({ type: 'BACKGROUND_TASKS_FETCH_REQUESTED' });

  return apiGet(`/api/v1/background_tasks?${filterParams}${pageParams}`).then(
    (response) => {
      dispatch({
        type: 'BACKGROUND_TASKS_FETCHED',
        ...normalize(response.body.data, new schema.Array(backgroundTask)),
        totalPages: response.body.totalPages,
        currentPage: response.body.currentPage,
        totalCount: response.body.totalCount,
      });
    },
  );
};

export const fetchBackgroundTask = (dispatch, { id }) => {
  return apiGet(`/api/v1/background_tasks/${id}`).then((response) => {
    dispatch({
      type: 'BACKGROUND_TASK_FETCHED',
      ...normalize(response.body.data, backgroundTask),
    });
  });
};

export const importSupplies = ({ file }, dispatch, props) => {
  if (!isFile(file)) return;

  return put('/api/v1/background_tasks/import_supplies')
    .use(middleware)
    .attach('import', file)
    .then((response) => {
      props.closeImportModal();
      notifySubmitSucceeded(dispatch, 'success', 'Planilha enviada!');
      dispatch({
        type: 'STARTED_IMPORT_SUPPLIES',
        ...normalize(response.body.data, backgroundTask),
      });
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response?.body?.errors });
    });
};

export const generatePricing = (dispatch) => {
  return apiPut('/api/v1/background_tasks/pricing').then((response) => {
    dispatch({
      type: 'PRICING_GENERATION_STARTED',
      ...normalize(response.body.data, backgroundTask),
    });
  });
};

export const generateFetchedOrdersReport = (dispatch, ids, filters) => {
  return apiPut('/api/v1/background_tasks/orders_report')
    .send({ ids: ids, dates: filters?.betweenDates })
    .then(() => {
      notifySubmitSucceeded(dispatch, 'success', 'Relatório solicitado!');
    })
    .catch(() => {
      notifySubmitFailed(
        dispatch,
        'error',
        'Nenhum pedido encontrado para relatório.',
      );
    });
};

export const generateWeeklyOrdersReport = (dispatch) => {
  return apiPut('/api/v1/background_tasks/weekly_orders_report')
    .then(() => {
      notifySubmitSucceeded(
        dispatch,
        'success',
        'Relatório semanal solicitado!',
      );
    })
    .catch(() => {
      notifySubmitFailed(
        dispatch,
        'error',
        'Nenhum pedido encontrado para relatório.',
      );
    });
};
