import React from 'react';
import Button from '../library/Button';
import { css } from 'glamor';
import CardActions from '../library/cards/CardActions';
import {
  CardForm,
  TextField,
  MultipleItemsField,
  DropDownField,
} from '../library/forms';

import Grid from '../library/Grid';
import { makeDropDownOptions, toCurrency } from '../../utils';
import Separator from '../library/Separator';
import Typography from '../library/Typography';
import Box from '../library/Box';
import { spacing } from '../library/theme';
import SupplyFormContainer from '../supplies/SupplyFormContainer';
import { FormHelperText } from '@mui/material';

const formTitleSx = {
  paddingBottom: spacing.s,
};
const errorsSx = css({
  whiteSpace: 'pre-wrap',
});

export default function RecipeForm(props) {
  const { supplies, recipe, recipes, cost, costErrors, currency } = props;

  const onModal = !!props?.closeCreateModal;

  return (
    <CardForm {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField name="name" label="Nome" required fluid />
        </Grid>
        <Grid item xs={12}>
          <MultipleItemsField
            name="parentConnectionsAttributes"
            data={recipes}
            label="Receitas"
            placeholder="Selecione uma receita existente"
            searchInputPlaceholder="Selecione uma receita existente"
            searchInputName="childRecipeId"
            editEnabled
            costEnabled={!costErrors}
            currency={currency}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleItemsField
            name="recipesSuppliesAttributes"
            data={supplies}
            label="Insumos"
            placeholder="Selecione um insumo existente"
            searchInputPlaceholder="Selecione um insumo existente"
            searchInputName="supplyId"
            editEnabled
            costEnabled={!costErrors}
            createForm={!onModal && SupplyFormContainer}
            createLabel={!onModal && 'Criar novo insumo'}
            currency={currency}
          />
        </Grid>
        <Grid item xs={12}>
          <Separator />
          {!costErrors && (
            <div>
              Custo estimado da receita:{' '}
              <strong style={{ color: 'black' }}>
                {toCurrency(cost, currency?.code)}
              </strong>
              *
              <FormHelperText>
                *Valor arredondado para a quantia monetária mais próxima.
              </FormHelperText>
            </div>
          )}
          {!!costErrors && (
            <div className={errorsSx}>
              <strong>Custo estimado da receita: </strong>
              {costErrors}
            </div>
          )}
          <Separator />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="number"
            name="preparationTime"
            label="Tempo ativo de preparo da receita (em minutos)"
            hint="Quanto tempo você realmente emprega neste preparo (desconsiderar tempo de forno, cocção, resfriamento fermentação, etc)?"
            minValue="1"
            fluid
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Separator />
          <Box sx={formTitleSx}>
            <Typography variant="formTitle">Rendimento</Typography>
            <Grid container spacing={3} pt={3}>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  label="Quantidade"
                  name="estimatedProduce"
                  placeholder="Rendimento"
                  minValue="0.001"
                  fluid
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <DropDownField
                  name="unitId"
                  label="Unidade de medida"
                  data={makeDropDownOptions(props.units, 'Selecione')}
                  fluid
                  required
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="notes"
            label="Observações"
            multiline={true}
            rows={5}
            fluid
          />
        </Grid>
      </Grid>
      <CardActions>
        <Button
          type="button"
          onClick={props.handleSubmit}
          color="success"
          size="large"
          disabled={props.pristine || props.submitting}
          fluid
        >
          {recipe ? 'Atualizar' : 'Adicionar'}
        </Button>
      </CardActions>
    </CardForm>
  );
}
