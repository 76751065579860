import React, { useCallback, useEffect } from 'react';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { fetchOrders } from '../../processes/orderProcesses';
import OrdersPage from './OrdersPage';
import {
  getFetchedIds,
  getOrderPageInfo,
  getOrdersByFetchedIds,
} from '../../selectors/orderSelectors';
import { formValueSelector } from 'redux-form';
import { getCurrentUserCompany } from '../../selectors/userSelectors';
import { getCurrencyById } from '../../selectors/configSelectors';
import { makeIsMobile } from '../../utils/useScreenWidth';
import FilterListIcon from '@mui/icons-material/FilterList';
import DateRangeIcon from '@mui/icons-material/DateRange';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  generateFetchedOrdersReport,
  generateWeeklyOrdersReport,
} from '../../processes/backgroundTaskProcesses';
import { useState } from 'react';

const FORM_NAME = 'orderSearch';

function OrdersPageContainer(props) {
  const { dispatch, currentPage, filters, fetchedIds } = props;
  const isMobile = makeIsMobile();

  useEffect(() => {
    if (!filters?.betweenDates?.length) return;

    fetchOrders(dispatch, { currentPage, filters, paged: true });
  }, [dispatch, currentPage, filters]);

  const onAdd = useCallback(() => {
    dispatch(replace('/orders/new'));
  }, [dispatch]);

  const fetchedOrdersReport = useCallback(() => {
    generateFetchedOrdersReport(dispatch, fetchedIds, filters);
  }, [dispatch, fetchedIds, filters]);

  const weeklyOrdersReport = useCallback(() => {
    generateWeeklyOrdersReport(dispatch);
  }, [dispatch]);

  const [showReports, setShowReports] = useState(false);
  const toggleReports = useCallback(() => {
    setShowReports(!showReports);
  }, [showReports]);
  const hideReports = useCallback(() => setShowReports(false));

  const reportActions = [
    {
      icon: <FilterListIcon />,
      name: 'Gerar relatório dos pedidos filtrados',
      onClick: fetchedOrdersReport,
    },
    {
      icon: <DateRangeIcon />,
      name: 'Relatório da próxima semana',
      onClick: weeklyOrdersReport,
    },
    {
      icon: <VisibilityIcon />,
      name: 'Histórico de relatórios',
      onClick: toggleReports,
    },
  ];

  const pageChangeHandler = useCallback(
    (_, currentPage = 1) => {
      dispatch({
        type: 'ORDERS_PAGE_CHANGED',
        currentPage,
      });
    },
    [dispatch],
  );

  return (
    <OrdersPage
      onAdd={onAdd}
      onPageChange={pageChangeHandler}
      isMobile={isMobile}
      reportActions={reportActions}
      showReports={showReports}
      hideReports={hideReports}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const formSelector = formValueSelector(FORM_NAME);
  const { scheduledAtFrom, scheduledAtTo } = formSelector(
    state,
    'scheduledAtFrom',
    'scheduledAtTo',
  );
  const betweenDates = [scheduledAtFrom, scheduledAtTo];
  const company = getCurrentUserCompany(state);
  const currency = getCurrencyById(state, company?.currencyId);
  const { currentPage, totalPages, totalCount, filters, loading } =
    getOrderPageInfo(state);

  return {
    orders: getOrdersByFetchedIds(state),
    fetchedIds: getFetchedIds(state),
    loading,
    currentPage,
    totalPages,
    totalCount,
    filters,
    scheduledAtFrom,
    scheduledAtTo,
    betweenDates,
    currency,
  };
}

export default connect(mapStateToProps)(OrdersPageContainer);
